// Base element style overrides
// stylelint-disable selector-no-type, selector-max-type, selector-max-specificity, selector-max-id

:root {
  color-scheme: $color-scheme;
}

* {
  box-sizing: border-box;
}

html {
  @include fs-4;

  scroll-behavior: smooth;
}

body {
  font-family: $body-font-family;
  font-size: inherit;
  line-height: $body-line-height;
  color: $body-text-color;
  background-color: $body-background-color;
  overflow-wrap: break-word;
}

ol,
ul,
dl,
pre,
address,
blockquote,
table,
div,
hr,
form,
fieldset,
noscript .table-wrapper {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
#toctitle {
  margin-top: 0;
  margin-bottom: 1em;
  font-weight: 500;
  line-height: $body-heading-line-height;
  color: $body-heading-color;
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
}

a {
  color: $link-color;
  text-decoration: none;
}

a:not([class]) {
  text-decoration: underline;
  text-decoration-color: $border-color;
  text-underline-offset: 2px;

  &:hover {
    text-decoration-color: rgba($link-color, 0.45);
  }
}

code {
  font-family: "Fira Code", "Microsoft Yahei", monospace;
  font-size: 0.85em;
  font-variant-ligatures: none;
  line-height: $body-line-height;
}

figure,
pre {
  margin: 0;
}

li {
  margin: 0.25em 0;
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  height: 1px;
  padding: 0;
  margin: $sp-6 0;
  background-color: $border-color;
  border: 0;
}

blockquote {
  border-left: 5px solid #7a7a7a !important;
  padding-left: 1rem !important;
  color: #34343c;
  background-color: #f1f1f1;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  margin: 0;
}

details {
  > div {
    padding: 0 1.25rem;
    margin: 1.25rem 0;
    border: solid #333;
    border-radius: 0.5rem;
  }

  > .highlighter-rouge {
    padding-top: 0;
    margin-top: 0.25rem;
    border: solid #333;
    border-radius: 0.5rem;
  }
}
