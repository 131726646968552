// Typography

// prettier-ignore
$body-font-family: system-ui, -apple-system, blinkmacsystemfont, "Segoe UI",
  roboto, "Helvetica Neue", arial, sans-serif, "Segoe UI Emoji" !default;
$mono-font-family: "SFMono-Regular", menlo, consolas, monospace !default;
$root-font-size: 16px !default; // DEPRECATED: previously base font-size for rems
$body-line-height: 1.4 !default;
$content-line-height: 1.6 !default;
$body-heading-line-height: 1.25 !default;

// Font size
// `-sm` suffix is the size at the small (and above) media query

$font-size-1: 0.5625rem !default;
$font-size-1-sm: 0.625rem !default;
$font-size-2: 0.6875rem !default; // h4 - uppercased!, h6 not uppercased, text-small
$font-size-3: 0.75rem !default; // h5
$font-size-4: 0.875rem !default;
$font-size-5: 1rem !default; // h3
$font-size-6: 1.125rem !default; // h2
$font-size-7: 1.5rem !default;
$font-size-8: 2rem !default; // h1
$font-size-9: 2.25rem !default;
$font-size-10: 2.625rem !default;
$font-size-10-sm: 3rem !default;

// Colors

$white: #fff !default;
$grey-dk-000: #959396 !default;
$grey-dk-100: #5c5962 !default;
$grey-dk-200: #44434d !default;
$grey-dk-250: #302d36 !default;
$grey-dk-300: #27262b !default;
$grey-lt-000: #f5f6fa !default;
$grey-lt-100: #eeebee !default;
$grey-lt-200: #ecebed !default;
$grey-lt-300: #e6e1e8 !default;
$purple-000: #7253ed !default;
$purple-100: #5e41d0 !default;
$purple-200: #4e26af !default;
$purple-300: #381885 !default;
$blue-000: #2c84fa !default;
$blue-100: #2869e6 !default;
$blue-200: #264caf !default;
$blue-300: #183385 !default;
$green-000: #41d693 !default;
$green-100: #11b584 !default;
$green-200: #009c7b !default;
$green-300: #026e57 !default;
$yellow-000: #ffeb82 !default;
$yellow-100: #fadf50 !default;
$yellow-200: #f7d12e !default;
$yellow-300: #e7af06 !default;
$red-000: #f77e7e !default;
$red-100: #f96e65 !default;
$red-200: #e94c4c !default;
$red-300: #dd2e2e !default;

// Spacing

$spacing-unit: 1rem; // 1rem == 16px

$spacers: (
  sp-0: 0,
  sp-1: $spacing-unit * 0.25,
  sp-2: $spacing-unit * 0.5,
  sp-3: $spacing-unit * 0.75,
  sp-4: $spacing-unit,
  sp-5: $spacing-unit * 1.5,
  sp-6: $spacing-unit * 2,
  sp-7: $spacing-unit * 2.5,
  sp-8: $spacing-unit * 3,
  sp-9: $spacing-unit * 3.5,
  sp-10: $spacing-unit * 4,
) !default;
$sp-1: map-get($spacers, sp-1) !default; // 0.25 rem == 4px
$sp-2: map-get($spacers, sp-2) !default; // 0.5  rem == 8px
$sp-3: map-get($spacers, sp-3) !default; // 0.75 rem == 12px
$sp-4: map-get($spacers, sp-4) !default; // 1    rem == 16px
$sp-5: map-get($spacers, sp-5) !default; // 1.5  rem == 24px
$sp-6: map-get($spacers, sp-6) !default; // 2    rem == 32px
$sp-7: map-get($spacers, sp-7) !default; // 2.5  rem == 40px
$sp-8: map-get($spacers, sp-8) !default; // 3    rem == 48px
$sp-9: map-get($spacers, sp-9) !default; // 3.5  rem == 56px
$sp-10: map-get($spacers, sp-10) !default; // 4  rem == 64px

// Borders

$border: 1px solid !default;
$border-radius: 4px !default;
$border-color: $grey-lt-100 !default;

// Grid system

$gutter-spacing: $sp-6 !default;
$gutter-spacing-sm: $sp-4 !default;
$nav-width: 16.5rem !default;
$nav-width-md: 15.5rem !default;
$nav-list-item-height: $sp-6 !default;
$nav-list-item-height-sm: $sp-8 !default;
$nav-list-expander-right: true;
$content-width: 75rem !default;
$header-height: 3.75rem !default;
$search-results-width: $content-width - $nav-width !default;
$transition-duration: 400ms;

// Media queries in pixels

$media-queries: (
  xs: 20rem,
  sm: 31.25rem,
  md: $content-width,
  lg: $content-width + $nav-width,
  xl: 87.5rem,
) !default;
