$body-font-family: "Source Sans Pro", "Microsoft Yahei", sans-serif;
$mono-font-family: "Source Sans Pro", "Microsoft Yahei", sans-serif;

* {
  font-family: $body-font-family;
}

$scrollbar-size: 6px;

::-webkit-scrollbar {
  width: $scrollbar-size;
  height: $scrollbar-size;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(#7253ed, 0.4);
  border-radius: calc(#{$scrollbar-size} / 2);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(#7253ed, 0.5);
  border-radius: calc(#{$scrollbar-size} / 2);
}

$pink-000: #f77ef1;
$pink-100: #f967f1;
$pink-200: #e94ee1;
$pink-300: #dd2cd4;

.lineno {
  padding-right: 0.5rem;
  min-width: 2.2rem;
  text-align: right;
  color: #9ea4ac;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

strong {
  code {
    font-weight: bold;
  }
}

@media all and (max-width: 1199px) {
  #core-wrapper {
    display: block;
  }

  .main-content-wrap {
    display: block;
  }

  #right-panel {
    display: none;
  }
}

@media all and (min-width: 1200px) {
  #core-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .main-content-wrap {
    flex: 0 0 75%;
    max-width: 75%;
  }

  #right-panel {
    flex: 0 0 25%;
    max-width: 25%;
  }

  #toc-wrapper {
    border-left: 1px solid rgba(158, 158, 158, 0.17);
    position: -webkit-sticky;
    position: sticky;
    top: 3rem;
    margin-top: 3rem;
    padding-left: 1rem;
    transition: top 0.2s ease-in-out;
    -webkit-animation: fade-up 0.8s;
    animation: fade-up 0.8s;
    font-size: 1rem;

    a {
      text-decoration: none;
    }
  }

  #toc-header {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.25rem;
    padding-bottom: 0.5rem;
  }

  ul.toc-list {
    padding: 0;
    font-size: 1rem;
    list-style-type: none;

    li.toc-list-item {
      padding-top: 2px;
      padding-bottom: 2px;
      list-style-type: none;

      > ul {
        font-size: 0.85rem;
        list-style-type: none;
        padding-inline-start: 1.25rem;
      }

      > a {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .toc-link:not(.is-active-link)::before {
    background-color: transparent !important;
  }

  .toc-link::before {
    height: 1.4rem !important;
  }
}
